<template>
     <div class="container-fluid py-4 add-student ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   عرض بيانات موظف المبيعات</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form role="form" class="text-start mt-3">
                       <div class="row">
                           <div class="mb-3 col-lg-6">
                      <label>  الاسم  </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="salesPersonN"
                    disabled
                  />
                </div>
                </div>

              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
export default {
  name: 'ShowSubject',
  components: {
    VmdInput
  },
  data () {
    return {
      salesPersonN: ''
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}salespeople/${this.$route.params.id}`).then(response => {
      this.salesPersonN = response.data.data.name
    })
  }
}
</script>
